import React from 'react';
import Grid from '@material-ui/core/Grid';
import { GroupTagsLinkPicture } from '../Links/GroupTagsLinkPicture';
import { MenuLegoSeries } from '../../constants/MenuEntries';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { GroupTagsLinkText } from '../Links/GroupTagsLinkText';
import { useMediaQuery } from '@material-ui/core';
import { CollapseArea } from '../CollapseArea/CollapseArea';

const LegoSeriesLinksView = ({ story }) => {
	const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	const realLinks = !story
		? MenuLegoSeries.items
		: _.cloneDeep(MenuLegoSeries.items).map((i) => {
				i.src = `/shop-ltoys/public${i.src}`;
				return i;
		  });

	const LegoSeriesLinks = () => {
		return (
			<Grid container spacing={3} justifyContent={'center'} alignItems={'baseline'}>
				{realLinks.map((i, idx) => {
					return (
						<Grid item key={idx} xs={6} sm={4} md={3}>
							{_.isNil(i.src) ? (
								<GroupTagsLinkText aspectRatio={1} {...i} />
							) : (
								<GroupTagsLinkPicture
									{...i}
									aspectRatio={i.src_orientation === 'landscape' ? 1.5 : 1}
									width={291}
									height={i.src_orientation === 'landscape' ? 197 : 291}
								/>
							)}
						</Grid>
					);
				})}
			</Grid>
		);
	};

	return !smDown ? (
		<LegoSeriesLinks />
	) : (
		<CollapseArea collapsedSize={450}>
			<LegoSeriesLinks />
		</CollapseArea>
	);
};

LegoSeriesLinksView.propTypes = { story: PropTypes.bool };
LegoSeriesLinksView.defaultProps = { story: false };

export { LegoSeriesLinksView };
