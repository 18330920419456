import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { FlatLinkBox } from './FlatLinkBox';

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FECF08',
		color: '#000',
		borderRadius: theme.spacing(1),
	},
	stretch: {
		width: '100%',
		height: '100%',
	},
}));

const GroupTagsLinkText = ({
	theme,
	title,
	grouptags,
	aspectRatio,
	className,
}) => {
	const classes = useStyles();

	const makeTypographySmaller = useMediaQuery((theme) =>
		theme.breakpoints.down('sm')
	);

	const tags = grouptags.join(',');
	const href = `/group?grouptags=${tags}`;
	return (
		<FlatLinkBox
			href={href}
			aspectRatio={aspectRatio}
			inputs={className}
			tr={title}
		/>
	);
};

GroupTagsLinkText.propTypes = {
	title: PropTypes.object.isRequired,
	grouptags: PropTypes.any.isRequired,
	aspectRatio: PropTypes.number,
	className: PropTypes.any,
};
GroupTagsLinkText.defaultProps = {
	aspectRatio: 1,
};

export { GroupTagsLinkText };
