import { LsShopCategoriesView } from '@lsoft/shared/components/ui/LsShopCategoriesView';
import { cobiCategories } from '../constants/cobiCategories';
import React from 'react';
import { ShopAgeLinksView } from './Views/ShopAgeLinksView';
import { Divider } from '@material-ui/core';
import { ShopTopButtons } from './Views/ShopTopButtons';
import { Tr } from '@lsoft/translate';
import { trLegoThemes } from '@lsoft/shared/translations/lego';
import { MenuCobiSeries } from '../constants/MenuEntries';

export const BrandTabContentCobi = () => {
	return (
		<div className={'flex flex-col gap-4 px-4 pt-8 pb-8'}>
			<div className={'text-xl font-medium'}>
				<Tr tr={MenuCobiSeries.title} />
			</div>
			<LsShopCategoriesView categories={cobiCategories} />
		</div>
	);
};
