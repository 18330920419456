import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MenuLegoShopperButton } from '../../constants/MenuEntries';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { GroupTagsLinkText } from '../Links/GroupTagsLinkText';
import { FlatLinkBox } from '../Links/FlatLinkBox';
import { tr_find_the_perfect_gift } from '../../translations/pages';

const ShopTopButtons = ({ story }) => {
	const realLinks = !story
		? MenuLegoShopperButton.items
		: _.cloneDeep(MenuLegoShopperButton.items).map((i) => {
				i.src = `/shop-ltoys/public${i.src}`;
				return i;
			});

	const grid_props = {
		xs: 12,
		sm: 6,
		md: 4,
		lg: 4,
	};

	return (
		<Grid
			container
			direction={'row'}
			justifyContent={'space-evenly'}
			spacing={2}
		>
			{realLinks.map((i, idx) => {
				return (
					<Grid item key={idx} {...grid_props}>
						<GroupTagsLinkText {...i} aspectRatio={4} />
					</Grid>
				);
			})}
			<Grid item key={'giftfinder'} {...grid_props}>
				<FlatLinkBox
					href={'/gift_finder'}
					tr={tr_find_the_perfect_gift}
					aspectRatio={4}
				/>
			</Grid>
		</Grid>
	);
};

ShopTopButtons.propTypes = { story: PropTypes.bool };
ShopTopButtons.defaultProps = { story: false };

export { ShopTopButtons };
