import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MenuLegoAge } from '../../constants/MenuEntries';
import { GroupTagsLinkPicture } from '../Links/GroupTagsLinkPicture';
import { Tr } from '@lsoft/translate';

const ShopAgeLinksView = () => {
	return (
		<div>
			<div className={'text-xl font-medium'}>
				<Tr
					tr={{
						DE: 'Für jedes Alter',
						EN: 'For every age',
						FR: 'Pour tous les âges',
					}}
				/>
			</div>
			<div>
				<Grid
					container
					direction={'row'}
					spacing={2}
					justifyContent={'space-around'}
				>
					{MenuLegoAge.items.map((i, idx) => (
						<Grid item key={idx} xs={4} sm={4} md={2} lg={2}>
							<GroupTagsLinkPicture {...i} height={300} width={300} />
						</Grid>
					))}
				</Grid>
			</div>
		</div>
	);
};

ShopAgeLinksView.propTypes = {};
ShopAgeLinksView.defaultProps = {};

export { ShopAgeLinksView };
