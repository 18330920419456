import React, { useRef, useState } from 'react';
import {
	MenuBooks,
	MenuLegoAge,
	MenuLegoEducationSeries,
	MenuLegoEducationStem,
	MenuLegoSeries,
	MenuLighting,
	MenuStorageBoxes,
	MenuOtherBrands,
} from '../../constants/MenuEntries';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { TagMenuHorizontal } from '../TagMenu/TagMenuHorizontal';
import { TagNavHorizontalPortalPlacer } from './TagNavHorizontalPortal';
import { TagNavHorizontalPortalButton } from './TagNavHorizontalPortalButton';

const useStyles = makeStyles((theme) => ({
	TagNavHorizontalContainer: {
		paddingTop: 0,
		paddingBottom: 0,
	},
}));

export const TagNavHorizontalContext = React.createContext(null);
TagNavHorizontalContext.displayName = 'TagNavHorizontalContext';

const TagNavHorizontal = ({ theme }) => {
	const classes = useStyles({ theme });

	const [selectedIconPortal, setSelectedIconPortal] = useState(null);
	const activeIconRef = useRef(null);

	const value = {
		activeIconRef,
		selectedIconPortal,
		setSelectedIconPortal,
	};

	return (
		<TagNavHorizontalContext.Provider value={value}>
			<div style={{ position: 'relative' }}>
				<Grid
					container
					justifyContent={'space-between'}
					className={clsx(classes.TagNavHorizontalContainer)}
					component={'nav'}
				>
					<Grid item>
						<TagNavHorizontalPortalButton name={'lego_age'}>
							<TagMenuHorizontal data={MenuLegoAge} />
						</TagNavHorizontalPortalButton>
					</Grid>
					<Grid item>
						<TagNavHorizontalPortalButton name={'lego_series'}>
							<TagMenuHorizontal data={MenuLegoSeries} />
						</TagNavHorizontalPortalButton>
					</Grid>
					{/*<Grid item>*/}
					{/*	<TagNavHorizontalPortalButton name={'mint'}>*/}
					{/*		<TagMenuHorizontal data={MenuLegoEducationStem} />*/}
					{/*	</TagNavHorizontalPortalButton>*/}
					{/*</Grid>*/}
					<Grid item>
						<TagNavHorizontalPortalButton name={'lightning'}>
							<TagMenuHorizontal data={MenuLighting} />
						</TagNavHorizontalPortalButton>
					</Grid>
					<Grid item>
						<TagNavHorizontalPortalButton name={'storage'}>
							<TagMenuHorizontal data={MenuStorageBoxes} />
						</TagNavHorizontalPortalButton>
					</Grid>
					<Grid item>
						<TagNavHorizontalPortalButton name={'other_brands'}>
							<TagMenuHorizontal data={MenuOtherBrands} />
						</TagNavHorizontalPortalButton>
					</Grid>
					<Grid item>
						<TagNavHorizontalPortalButton name={'books'}>
							<TagMenuHorizontal data={MenuBooks} />
						</TagNavHorizontalPortalButton>
					</Grid>
				</Grid>
				<TagNavHorizontalPortalPlacer />
			</div>
		</TagNavHorizontalContext.Provider>
	);
};

TagNavHorizontal.propTypes = {};
TagNavHorizontal.defaultProps = {};

export { TagNavHorizontal };
