import { cva } from 'class-variance-authority';
import Link from 'next/link';
import { cn } from '@lsoft/ui/lib/utils';
import Image from 'next/image';
import React, { useEffect } from 'react';
import { TabsList, TabsTrigger } from '@lsoft/shadcn/components/ui/tabs';

const BrandTabVariants = cva(
	'h-full w-full text-center ' +
		'border-t-2 border-t-gray-300 ' +
		'rounded-t-xl flex flex-row justify-center items-center border-l border-r',
	{
		variants: {
			selected: {
				true: 'border-b-0 bg-[#fafafa] border-l-0',
				false: 'border-b-2 bg-[#f0f0f0] cursor-pointer',
			},
		},
	},
);

export const BrandTab = ({ name, selected = false, src, href = '#' }) => {
	return (
		<Link href={href} className={cn(BrandTabVariants({ selected }))}>
			{/*<div className={cn(BrandTabVariants({ selected }))}>*/}
			{!src && <div>{name}</div>}
			{src && (
				<Image
					alt={name}
					src={src}
					width={200}
					height={100}
					className={'object-contain w-4/5 h-4/5'}
				/>
			)}
			{/*</div>*/}
		</Link>
	);
};

export const BrandTabTrigger = React.forwardRef(
	({ name, selected = false, src, href = '#', className }, ref) => {
		return (
			<TabsPrimitive.Trigger
				value={name}
				ref={ref}
				className={cn(BrandTabVariants({ selected }))}
			>
				{!src && <div>{name}</div>}
				{src && (
					<Image
						alt={name}
						src={src}
						width={200}
						height={100}
						className={'object-contain w-4/5 h-4/5'}
					/>
				)}
			</TabsPrimitive.Trigger>
		);
	},
);
BrandTabTrigger.displayName = TabsPrimitive.Trigger.displayName;

import * as TabsPrimitive from '@radix-ui/react-tabs';

export const BrandTabs = ({ selected }) => {
	/*
	 * MANUFACTURER/COBI,
	 * MANUFACTURER/BARBIE,
	 * MANUFACTURER/BRUDER,
	 * MANUFACTURER/HOT_WHEELS,
	 * MANUFACTUERE/MEGA,
	 * MANUFACTURER/POKEMON
	 * */
	return (
		<TabsPrimitive.List>
			<div
				className={
					'w-full h-14 md:h-20 bg-white flex flex-row divide-x-2 divide-gray-300'
				}
			>
				<BrandTabTrigger
					selected={selected === 'lego'}
					name={'lego'}
					src={'/images/brands/lego.png'}
				/>
				<BrandTabTrigger
					selected={selected === 'cobi'}
					name={'cobi'}
					src={'/images/brands/cobi.png'}
				/>

				<BrandTab
					name={'MEGA'}
					src={'/images/brands/mega.png'}
					href={'/group?grouptags=BRANDS/MEGA'}
				/>
				<BrandTab
					name={'CADA'}
					src={'/images/brands/cada.png'}
					href={'/group?grouptags=BRANDS/CADA'}
				/>
				<BrandTab
					name={'PANTASY'}
					src={'/images/brands/pantasy.png'}
					href={'/group?grouptags=BRANDS/PANTASY'}
				/>
				<BrandTab
					name={'HOT_WHEELS'}
					src={'/images/brands/hotwheels.png'}
					href={'/group?grouptags=BRANDS/HOT_WHEELS'}
				/>
				{/*<BrandTab*/}
				{/*	name={'BARBIE'}*/}
				{/*	src={'/images/brands/barbie.png'}*/}
				{/*	href={'/group?grouptags=BRANDS/BARBIE'}*/}
				{/*/>*/}
				<BrandTab
					name={'POKEMON'}
					src={'/images/brands/pokemon.png'}
					href={'/group?grouptags=BRANDS/POKEMON'}
				/>
				{/*<BrandTab*/}
				{/*	name={'BRUDER'}*/}
				{/*	src={'/images/brands/bruder.png'}*/}
				{/*	href={'/group?grouptags=BRANDS/BRUDER'}*/}
				{/*/>*/}
			</div>
		</TabsPrimitive.List>
	);
};
