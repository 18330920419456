import _ from 'lodash';
import { TTranslation } from '@shared/types';

export const tr = (tr: string | TTranslation | undefined) => {
  if (_.isNil(tr)) return null;
  if (Object.keys(tr).length === 0) return null;
  if (_.isString(tr)) return tr;
  const locale = 'de';
  const d = _.mapKeys(tr, (v: string, k: string) => {
    return k.toLowerCase();
  });

  return (
    _.get(d, 'all') || _.get(d, locale) || _.get(d, 'en') || _.get(d, '[0]')
  );
};
