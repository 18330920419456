import React from 'react';
import { TProps, TTranslation } from '@shared/types';
import { cn } from '@shadcn/lib/utils';
import Image from 'next/image';
import Link from 'next/link';
// @ts-ignore
import { Tr } from '@lsoft/translate';
import { tr } from '@shared/legacy_compat/nextjs/translate';
import { AspectRatio } from '@shadcn/ui/aspect-ratio';

export type TLsShopCategory = {
  imageSrc: string;
  text: TTranslation;
  href: string;
};

type LsShopCategoriesViewProps = TProps<{
  categories: TLsShopCategory[];
}>;

export const LsShopCategoriesView = ({
  categories,
  className,
}: LsShopCategoriesViewProps) => {
  return (
    <div
      className={cn(
        'grid gap-2',
        'grid-cols-2',
        'sm:grid-cols-3',
        'lg:grid-cols-4',
        className,
      )}
    >
      {categories.map((itemData: TLsShopCategory) => (
        <LsShopCategoriesViewItem key={itemData.href} itemData={itemData} />
      ))}
    </div>
  );
};

type LsShopCategoriesViewItemProps = TProps<{
  itemData: TLsShopCategory;
}>;

export const LsShopCategoriesViewItem = ({
  itemData,
}: LsShopCategoriesViewItemProps) => {
  return (
    <Link href={itemData.href}>
      <div className={cn('flex flex-col')}>
        <AspectRatio
          ratio={4 / 3}
          className={cn(
            'border-gray-300 border-t border-x border-b-0 rounded-t-xl bg-white flex flex-row items-center justify-center',
          )}
        >
          <div className={cn('max-w-full max-h-full ')}>
            <Image
              src={itemData.imageSrc}
              alt={tr(itemData.text) as string}
              width={400}
              height={300}
              className={cn(
                'scale-75 hover:scale-90 transition-all duration-500, bg-white',
              )}
            />
          </div>
        </AspectRatio>
        <div
          className={cn(
            'w-full text-center text-lg p-2 text-white bg-red-600 rounded-b-xl',
          )}
        >
          <Tr tr={itemData.text} />
        </div>
      </div>
    </Link>
  );
};
