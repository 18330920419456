import { Tr } from '@lsoft/translate';
import { ShopAgeLinksView } from './Views/ShopAgeLinksView';
import { Divider } from '@material-ui/core';
import { ShopTopButtons } from './Views/ShopTopButtons';
import { LegoSeriesLinksView } from './Views/LegoSeriesLinksView';
import React from 'react';
import { trLegoThemes } from '@lsoft/shared/translations/lego';

export const BrandTabContentLego = (props) => {
	return (
		<div className={'flex flex-col gap-4 px-4 pt-8'}>
			<div className={'text-xl font-medium'}>
				<Tr tr={trLegoThemes} />
			</div>
			<LegoSeriesLinksView />
		</div>
	);
};
