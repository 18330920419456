import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MuiLink } from '@lsoft/mui';
import _ from 'lodash';
import { NextAssetImage } from '@lsoft/shared/components/ui/NextAssetImage';
import { getTranslation } from '@lsoft/translate';
import { useRouter } from 'next/router';

const GroupTagsLinkPicture = ({ src, grouptags, height, width, title }) => {
	const { locale } = useRouter();
	const alt_text =
		typeof title === 'string' ? title : getTranslation(title, locale);

	const asset = {
		asset_type: 'LINK_PICTURE',
		url: _.isString(src) ? { ALL: src } : src,
		url_type: 'RELATIVE',
		caption: alt_text,
		active: true,
	};

	const CustomLink = useCallback(
		({ children }) => {
			if (_.isEmpty(grouptags)) {
				return <Fragment>{children}</Fragment>;
			} else {
				const tags = grouptags.join(',');
				const link = `/group?grouptags=${tags}`;
				return (
					<MuiLink href={link} fullWidth>
						{children}
					</MuiLink>
				);
			}
		},
		[grouptags]
	);

	return (
		<CustomLink>
			<NextAssetImage
				asset={asset}
				height={height}
				width={width}
				alt={alt_text}
			/>
		</CustomLink>
	);
};

GroupTagsLinkPicture.propTypes = {
	src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	grouptags: PropTypes.any,
	height: PropTypes.number,
	width: PropTypes.number,
};
GroupTagsLinkPicture.defaultProps = {
	grouptags: [],
	height: 315,
	width: 315,
};

export { GroupTagsLinkPicture };
