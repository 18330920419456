import { MuiAspectRatio, MuiLink } from '@lsoft/mui';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Tr } from '@lsoft/translate';
import React from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FECF08',
		color: '#000',
		borderRadius: theme.spacing(1),
	},
	stretch: {
		width: '100%',
		height: '100%',
	},
}));

export const FlatLinkBox = (props) => {
	const classes = useStyles();
	const makeTypographySmaller = useMediaQuery((theme) =>
		theme.breakpoints.down('sm')
	);
	return (
		<MuiLink href={props.href} fullWidth>
			<MuiAspectRatio aspectRatio={props.aspectRatio}>
				<div className={clsx(classes.root, classes.stretch, props.inputs)}>
					<Grid
						container
						direction={'row'}
						alignItems={'center'}
						justifyContent={'center'}
						className={clsx(classes.stretch)}
					>
						<Grid item>
							<Typography variant={makeTypographySmaller ? 'subtitle1' : 'h6'}>
								<Tr tr={props.tr} />
							</Typography>
						</Grid>
					</Grid>
				</div>
			</MuiAspectRatio>
		</MuiLink>
	);
};

FlatLinkBox.propTypes = {
	href: PropTypes.string,
	aspectRatio: PropTypes.any,
	inputs: PropTypes.any,
	makeTypographySmaller: PropTypes.bool,
	tr: PropTypes.any,
};
