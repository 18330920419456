import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { MuiPortal } from '@lsoft/mui';
import { createStyles, makeStyles } from '@material-ui/core';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TagNavHorizontalContext } from './TagNavHorizontal';

const useStyles = makeStyles((theme) =>
	createStyles({
		TagNavHorizontalPortal: {
			position: 'absolute',
			top: 52,
			right: 0,
			left: 0,
			zIndex: theme.zIndexes.HeaderIconPortal,
		},
		TagNavHorizontalPortalPaper: {
			borderRadius: 0,
			[theme.breakpoints.up('sm')]: {
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
			},
		},
		TagNavHorizontalPortalPlacer: {
			position: 'relative',
			margin: [[0, 'auto']],
		},
	})
);
const TagNavHorizontalPortal = ({ children }) => {
	return (
		<MuiPortal id={'HorizontalMenuPortal'}>
			<NoSsr>{children}</NoSsr>
		</MuiPortal>
	);
};

const TagNavHorizontalPortalPlacer = ({ theme, children }) => {
	const classes = useStyles({ theme });
	const horizontalMenuPortalRef = useRef(null);
	const {
		activeIconRef,
		selectedIconPortal,
		setSelectedIconPortal,
	} = useContext(TagNavHorizontalContext);

	useEffect(() => {
		if (!_.isNil(selectedIconPortal)) {
			document.addEventListener('mousedown', handleOutsideClick);
			return () => {
				document.removeEventListener('mousedown', handleOutsideClick);
			};
		}
	}, [selectedIconPortal]);

	const handleOutsideClick = useCallback(
		(e) => {
			const outside = !horizontalMenuPortalRef.current.contains(e.target);
			const notIcon = !activeIconRef.current.contains(e.target);
			if (outside && notIcon) setSelectedIconPortal(null);
		},
		[horizontalMenuPortalRef, setSelectedIconPortal, activeIconRef]
	);

	return (
		<div
			className={clsx(classes.TagNavHorizontalPortal)}
			id={'HorizontalMenuPortal'}
			ref={horizontalMenuPortalRef}
		>
			{children}
		</div>
	);
};

const TagNavHorizontalPortalPaper = ({ theme, children, className }) => {
	const classes = useStyles(theme);
	return (
		<Paper
			className={clsx(classes.TagNavHorizontalPortalPaper, className)}
			elevation={4}
		>
			{children}
		</Paper>
	);
};

TagNavHorizontalPortalPaper.propTypes = {
	className: PropTypes.string,
};

export {
	TagNavHorizontalPortal,
	TagNavHorizontalPortalPlacer,
	TagNavHorizontalPortalPaper,
};
