import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import {
	Card,
	CardContent,
	CardMedia,
	Grid,
	MenuItem,
	MenuList,
	Typography,
} from '@material-ui/core';
import { Tr } from '@lsoft/translate';
import { MuiLink } from '@lsoft/mui';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import { TagNavHorizontalPortalButton } from '../TagNavigation/TagNavHorizontalPortalButton';
import {
	TagNavHorizontalPortal,
	TagNavHorizontalPortalPaper,
} from '../TagNavigation/TagNavHorizontalPortal';
import Image from 'next/image';

export const TagMenuHorizontal = ({ data }) => {
	const { title, description, src, items } = data;
	const { buttonRef, close, isOpen, onButtonClick } = useContext(
		TagNavHorizontalPortalButton.Context
	);
	const rows = _.chunk(items, 12);

	// Return link with button, if only one child item is available
	if (items.length === 1)
		return (
			<MuiLink href={`/group?grouptags=${items[0].grouptags}`}>
				<Button>
					<Tr tr={title} html />
				</Button>
			</MuiLink>
		);

	return (
		<Fragment>
			<Button ref={buttonRef} onClick={onButtonClick}>
				<Tr tr={title} html />
			</Button>
			{isOpen && (
				<TagNavHorizontalPortal>
					<TagNavHorizontalPortalPaper>
						<Grid container spacing={2}>
							<Grid item xs={12} md={9}>
								<MenuList
									subheader={
										<ListSubheader color={'primary'} disableSticky>
											<Tr tr={title} />
										</ListSubheader>
									}
								>
									<Grid container spacing={2}>
										{rows.map((row_items, index) => {
											return (
												<Grid item key={index} xs={12} sm={4} md={4}>
													{row_items
														.filter(
															(item) =>
																_.get(item, 'grouptags', []).length !== 0
														)
														.map((item, idx) => {
															const tags = item.grouptags.join(',');
															const link = `/group?grouptags=${tags}`;
															return (
																<MuiLink
																	fullWidth
																	href={link}
																	linkprops={{
																		onClick: close,
																		color: 'textPrimary',
																	}}
																	key={idx}
																>
																	<MenuItem>
																		<Typography noWrap>
																			<Tr tr={item.title} html />
																		</Typography>
																	</MenuItem>
																</MuiLink>
															);
														})}
												</Grid>
											);
										})}
									</Grid>
								</MenuList>
							</Grid>
							{!_.isNil(description) && (
								<Grid item xs={12} md={3}>
									<Card style={{ marginRight: 16, marginLeft: 16 }}>
										<CardMedia component="div">
											<Image
												src={src}
												alt={'LToys - Bricks, Figures & More'}
												layout={'intrinsic'}
												priority
												loading={'eager'}
												width={300}
												height={225}
											/>
											{/*<NextAssetImage*/}
											{/*	asset={{*/}
											{/*		ASSET_TYPE: 'LINK_PICTURE',*/}
											{/*		URL: _.isString(src) ? { ALL: src } : src,*/}
											{/*		URL_TYPE: 'RELATIVE',*/}
											{/*		CAPTION: description.text,*/}
											{/*		ACTIVE: true,*/}
											{/*	}}*/}
											{/*/>*/}
										</CardMedia>
										<CardContent>
											<Typography gutterBottom variant="h5" component="h2">
												<Tr tr={description.headline} />
											</Typography>
											<Typography
												variant="body2"
												color="textSecondary"
												component="p"
											>
												<Tr tr={description.text} />
											</Typography>
										</CardContent>
									</Card>
								</Grid>
							)}
						</Grid>
					</TagNavHorizontalPortalPaper>
				</TagNavHorizontalPortal>
			)}
		</Fragment>
	);
};
TagMenuHorizontal.propTypes = {
	data: PropTypes.object,
	variant: PropTypes.string,
};

TagMenuHorizontal.defaultProps = {
	data: {
		title: {},
		items: [],
	},
	variant: 'sidebar',
};
